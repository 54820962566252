.appVersion {
	// position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	color: #d3dee3;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 5px;
	font-size: 9px;
	font-style: italic;
}
