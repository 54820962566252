.bottomBar {
	background: #262829;
	height: 60px;
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 0;
	display: flex;
	z-index: 2;
	a {
		flex-grow: 1;
		padding: 9px 5px;
		display: block;
		width: 20%;
		text-transform: uppercase;
		font-family: dinpro;
		color: #d3dee3;
		text-decoration: none;
		line-height: 1;
		text-align: center;
		align-self: center;
		&.active {
			color: red;
		}
		[class^='icon-'],
		[class*=' icon-'] {
			font-size: 1.813rem;
			color: #d3dee3;
			margin-bottom: 5px;
		}
		span {
			display: block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 0.625rem;
			text-transform: capitalize;
		}
		&.active {
			color: #2a8fbd;
			[class^='icon-'],
			[class*=' icon-'] {
				color: #2a8fbd;
			}
		}
		&:nth-child(4) {
			flex-grow: 12;
			background: #393939;
			border-radius: 30px 0 0 30px;
		}
	}
}
