.sideBarContainer {
	height: 100%;
	width: 100%;
}

.rightSideBarOutside {
	height: 100%;
	width: 100%;
	opacity: 0.5;
	z-index: 4;
	position: absolute;
}

.rightSideBar {
	color: white;
	background-color: #023144;
	position: absolute;
	top: 0;
	right: 0;
	width: 300px;
	height: 100%;
	padding: 15px;
	z-index: 4;
	overflow-y: auto;
	right: -500px;
	transition: all 0.2s linear;
}

.rightSideBar.toggled {
	right: 0;
	transition: all 0.2s linear;
}
