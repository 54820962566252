.defaultForm {
	position: relative;
	padding: 15px;
	display: block;

	a {
		font-size: 0.75rem;
		color: #2a8fbd;
		text-decoration: none;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		box-shadow: 0 0 0 100px #023144 inset;
		background-clip: content-box !important;
		-webkit-text-fill-color: #fff;
		-moz-text-fill-color: #fff;
		-o-text-fill-color: #fff;
	}

	.captchaTitle {
		font-weight: 400;
		font-size: 0.875rem;
		color: #dab867;
		margin-bottom: 10px;
		display: block;
		max-width: 100%;
	}

	.dobDropdown::-webkit-calendar-picker-indicator {
		color: transparent;
		background: none;
		z-index: 2;
		-webkit-appearance: none;
	}

	.container {
		display: flex;
		align-items: center;

		.formGroup {
			margin-bottom: 20px;
			width: 100%;
			position: relative;
		}

		.noValue {
			margin-bottom: 20px;
			width: 100%;
			position: relative;
			> div > div > p {
				color: #999;
			}
		}

		.calendar {
			position: absolute;
			right: 5px;
			margin-top: 27px;
			pointer-events: none;
		}

		.captchaContainer {
			display: flex;
			flex-wrap: wrap;

			img {
				width: auto;
				height: 38px;
				margin-right: 8px;
			}

			.reloadCaptcha {
				background-color: #2a8fbd;
				border-color: #2a8fbd;
				color: #fff;
				padding: 11px 14px;
				border: none;
				text-transform: uppercase;
			}
		}
	}

	[class^='icon-password'],
	[class*='icon-profile'],
	[class*='icon-security'],
	[class*='icon-mail'],
	[class*='icon-phone'],
	[class*='icon-currency'],
	[class*='icon-event'],
	[class*='icon-referral-bonus'] {
		font-size: 1.813rem;
		color: #2a8fbd;
		padding-right: 15px;
		// padding-top: 15px;
	}

	// Meter bar
	.strengthIndicator {
		margin-top: 20px;
		width: 100%;
		color: #bbb;
		font-size: 0.875rem;

		p {
			display: block;

			span:first-child {
				margin-right: 5px;

				&:after {
					display: inline-block;
					content: ':';
				}
			}
		}

		&.weak {
			> span:first-child {
				background-color: red;
			}
		}

		&.mediumLow {
			> span:nth-child(-n + 2) {
				background-color: yellow;
			}
		}

		&.mediumHigh {
			> span:nth-child(-n + 2) {
				background-color: orange;
			}
		}

		&.strong {
			> span {
				background-color: green;
			}
		}

		.meter {
			margin-right: 5px;
			width: 25%;
			display: inline-block;
			margin-bottom: 1em;
			background-color: rgba(0, 0, 0, 0.1);
			height: 0.5em;
		}
	}

	form {
		div {
			> .icomoon {
				img {
					height: auto;
					width: 25px !important;
					margin-right: 20px;
					padding-top: 20px;
				}
			}
			> span {
				.usernameSuggestions {
					font-size: 12px;
					line-height: 1.5;
					> span {
						color: red;
					}
					> div {
						.usernameAvailable {
							color: #bbbbbb;
						}

						.username {
							color: #2a8fbd;
							margin-right: 5px;
							margin-left: 5px;
							font-weight: 900;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}

html[lang='en'],
html[lang='in'],
html[lang='au'],
html[lang='id'] {
	.defaultForm {
		a, button {
			font-family: 'DIN Pro', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif' !important;
		}
	}
}

html[lang='kh'] {
	.defaultForm {
		a, button {
			font-family: 'Noto Sans Khmer', 'DIN Pro, Kantumruy', 'Khmer OS System',
				'sans-serif' !important;
		}
	}
}

html[lang='kr'] {
	.defaultForm {
		a, button {
			font-family: 'Noto Sans KR', 'Gothic A1', 'Nanum Gothic', 'Malgun Gothic',
				'sans-serif' !important;
		}
	}
}

html[lang='th'] {
	.defaultForm {
		a, button {
			font-family: 'Sarabun', 'Krub', 'CS PraKas', 'Helvetica', 'Arial',
				'sans-serif' !important;
		}
	}
}

html[lang='vn'] {
	.defaultForm {
		a, button {
			font-family: 'Roboto', 'Open Sans', 'Source Sans Pro', 'Noto Sans',
				'Arial', 'sans-serif' !important;
		}
	}
}

html[lang='cn'] {
	.defaultForm {
		a, button {
			font-family: 'Hiragino Sans GB', 'Microsoft YaHei', 'Noto Sans SC, SimHei', 'sans-serif' !important;
		}
	}
}


