[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-live-chat:before {
	content: '\e90a';
}
.icon-discover:before {
	content: '\e90b';
}
.icon-freebets:before {
	content: '\e90c';
}
.icon-gadgets-new:before {
	content: '\e90d';
}
.icon-rewards-home:before {
	content: '\e90e';
}
.icon-lifestyle-new:before {
	content: '\e90f';
}
.icon-luxury:before {
	content: '\e910';
}
.icon-online-deals-new:before {
	content: '\e911';
}
.icon-shopping-bag:before {
	content: '\e912';
}
.icon-Sports:before {
	content: '\e914';
}
.icon-vip-rewards:before {
	content: '\e915';
}
.icon-spin-wheel:before {
	content: '\e916';
}
.icon-profile:before {
	content: '\e632';
}
.icon-back:before {
	content: '\e968';
}
.icon-close:before {
	content: '\e974';
}
.icon-filter:before {
	content: '\e97e';
}
.icon-more:before {
	content: '\e98c';
}
.icon-shopping-cart:before {
	content: '\e900';
}
.icon-arrow-up2:before {
	content: '\ea3a';
}
.icon-arrow-down2:before {
	content: '\ea3e';
}
.icon-event:before {
	content: '\e92a';
}
