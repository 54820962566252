.text {
	color: #dab867;
	font-size: 0.75rem;
}

html[lang='en'],
html[lang='in'],
html[lang='au'],
html[lang='id'] {
	.text > p {
		font-family: 'DIN Pro', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif' !important;
	}
}

html[lang='kh'] {
	.text > p  {
		font-family: 'Noto Sans Khmer', 'DIN Pro, Kantumruy', 'Khmer OS System',
			'sans-serif' !important;
	}
}

html[lang='kr'] {
	.text > p {
		font-family: 'Noto Sans KR', 'Gothic A1', 'Nanum Gothic', 'Malgun Gothic',
			'sans-serif' !important;
	}
}

html[lang='th'] {
	.text > p  {
		font-family: 'Sarabun', 'Krub', 'CS PraKas', 'Helvetica', 'Arial',
			'sans-serif' !important;
	}
}

html[lang='vn'] {
	.text > p  {
		font-family: 'Roboto', 'Open Sans', 'Source Sans Pro', 'Noto Sans', 'Arial',
			'sans-serif' !important;
	}
}

html[lang='cn'] {
	.text > p  {
		font-family: 'Hiragino Sans GB', 'Microsoft YaHei', 'Noto Sans SC, SimHei', 'sans-serif' !important;
	}
}

.textcomp {
	margin: 10px 0;
	.container {
		max-width: 1210px;
		padding: 0 20px;
		margin: 0 auto;
	}
	.heading {
		font-size: 40px;
		margin-top: 40px;
		display: block;
	}
	.center {
		text-align: center;
	}
}
