.popupPage {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;
	background: #023144;
	transition: left 0.2s linear;
	&.animate {
		left: 0%;
	}
	&.close {
		left: 100%;
	}
	.header {
		width: 100%;
		padding: 11px 10px;
		background-color: #1f2021;
		span {
			display: inline-block;
			margin-right: 10px;
			color: #2a8fbd;
			font-size: 1.7rem;
		}
		h3 {
			display: inline-block;
			font-size: 18px;
			max-width: 250px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			color: #d3dee3;
			font-weight: 400;
			margin: 0;
			padding: 0;
		}
	}
	.body {
		height: calc(100% - 51px);
		overflow-y: scroll;
	}
}
