.formInput {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	width: 100%;

	div {
		position: relative;
		.dateValue {
			font-size: 14px;
			padding: 10px 1px;
			outline: 0;
			color: #d3dee3;
			margin: 0;
			position: absolute;
			z-index: 2;
			top: 4px;
		}
	}
	input {
		font-size: 14px;
		padding: 10px 0;
		border: 0;
		border-bottom: 1px solid #476370;
		border-radius: 0;
		background-color: initial;
		outline: 0;
		color: #d3dee3;
		resize: none;
		width: 100%;
		min-height: 41px;

		&:focus {
			border-color: #2a8fbd;
		}
		&:disabled {
			opacity: 0.5;
		}
		&[type='date'] {
			-webkit-appearance: none;
			text-indent: -9999px;
			min-height: 40px;
		}
	}
	.errorMessage {
		color: red;
		font-size: 10px;
	}

	#has-error {
		border-bottom: 1px solid red;
	}
	label {
		font-weight: 400;
		font-size: 0.875rem;
		color: #dab867;
		margin-bottom: 0;
		display: block;
		max-width: 100%;
	}
	input[type='date']::-webkit-clear-button,
	input[type='date']::-webkit-inner-spin-button {
		display: none;
	}

	input[type='date'] {
		-webkit-appearance: none;
	}
}

html[lang='en'],
html[lang='in'],
html[lang='au'],
html[lang='id'] {
	.formInput {
		font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif' !important;
		label,
		a, * {
			font-family: 'DIN Pro', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif' !important;
		}
	}
}

html[lang='kh'] {
	.formInput {
		font-family: 'Kantumruy', 'Open Sans', 'Khmer OS System', 'sans-serif' !important;
		label,
		a {
			font-family: 'Noto Sans Khmer', 'DIN Pro, Kantumruy', 'Khmer OS System',
				'sans-serif' !important;
		}
	}
}

html[lang='kr'] {
	.formInput {
		font-family: 'Gothic A1', 'Nanum Gothic', 'Malgun Gothic', 'sans-serif' !important;
		label,
		a {
			font-family: 'Noto Sans KR', 'Gothic A1', 'Nanum Gothic', 'Malgun Gothic',
				'sans-serif' !important;
		}
	}
}

html[lang='th'] {
	.formInput {
		font-family: 'Krub', 'CS PraKas', 'Helvetica', 'Arial', 'sans-serif' !important;
		label,
		a {
			font-family: 'Sarabun', 'Krub', 'CS PraKas', 'Helvetica', 'Arial',
				'sans-serif' !important;
		}
	}
}

html[lang='vn'] {
	.formInput {
		font-family: 'Open Sans', 'Source Sans Pro', 'Noto Sans', 'Arial',
			'sans-serif' !important;
		label,
		a {
			font-family: 'Roboto', 'Open Sans', 'Source Sans Pro', 'Noto Sans',
				'Arial', 'sans-serif' !important;
		}
	}
}

html[lang='cn'] {
	.formInput {
	  font-family: 'Noto Sans SC', 'SimHei', 'sans-serif' !important;
	  label,
	  a {
		  font-family: 'Hiragino Sans GB', 'Microsoft YaHei', 'Noto Sans SC, SimHei', 'sans-serif' !important;
		}
	}
}
