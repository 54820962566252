@import '../../../style/variables';

.backtotop {
	position: fixed;
	z-index: 1;
	background-color: var(--backtotop-bgcolor);
	width: 45px;
	height: 45px;
	bottom: -100px;
	right: 15px;
	border-radius: 100px;
	opacity: var(--backtotop-opacity);
	display: block;
	margin: 0;
	padding: 0;
	transition: all 0.4s;
	&.active {
		bottom: var(--backtotop-active-pos);
	}
	span {
		border-top: 1px solid #fff;
		border-right: 1px solid #fff;
		display: block;
		width: 10px;
		height: 10px;
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}
