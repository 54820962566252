@import './icomoon';
@import './variables';

html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #fafafa;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #023144;
	overflow: hidden;

	.clickable {
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
		outline: 0;
	}
}

* {
	outline: none;
	box-shadow: none;
	box-sizing: border-box;
	font-family: 'dinpro', arial, sans-serif;
}

#app {
	height: 100%;
}

// Livechat container
.lpdv {
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	z-index: 10;
	.lpc_window {
		position: fixed !important;
		width: 90% !important;
		height: 90% !important;
		left: 5% !important;
		top: 5% !important;
	}
}
// Livechat Button
.LPMcontainer {
	display: none !important;
}

@font-face {
	font-family: 'dinpro';
	src: url('/assets/fonts/din-regular-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #fafafa;
	font-weight: 400;
	color: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/*background: #023144;*/
	background: #121e2f;
}

body {
	height: auto;
}

#page-widgets {
	overflow-x: hidden;
}

* {
	box-sizing: border-box;
	font-family: 'dinpro', arial, sans-serif;
}

#app {
	height: 100%;
}

.help-block {
	margin-top: 5px;
	display: block;
	color: #bbbbbb;
	font-size: 0.75rem;
	margin-bottom: 0;
}

html[lang='en'],
html[lang='in'],
html[lang='au'],
html[lang='id'] {
	.help-block {
		font-family: 'DIN Pro', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif' !important;
	}
}

html[lang='kh'] {
	.help-block {
		font-family: 'Noto Sans Khmer', 'DIN Pro, Kantumruy', 'Khmer OS System',
			'sans-serif' !important;
	}
}

html[lang='kr'] {
	.help-block {
		font-family: 'Noto Sans KR', 'Gothic A1', 'Nanum Gothic', 'Malgun Gothic',
			'sans-serif' !important;
	}
}

html[lang='th'] {
	.help-block {
		font-family: 'Sarabun', 'Krub', 'CS PraKas', 'Helvetica', 'Arial',
			'sans-serif' !important;
	}
}

html[lang='vn'] {
	.help-block {
		font-family: 'Roboto', 'Open Sans', 'Source Sans Pro', 'Noto Sans', 'Arial',
			'sans-serif' !important;
	}
}

html[lang='cn'] {
	.help-block {
		font-family: 'Hiragino Sans GB', 'Microsoft YaHei', 'Noto Sans SC, SimHei', 'sans-serif' !important;
	}
}