.button {
  display: block;
  width: 100%;
  background-color: #2a8fbd;
  border-color: #2a8fbd;
  color: #fff;
  text-transform: uppercase;
  user-select: none;
  text-align: center;
  outline: 0;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  border: 0;
  padding: 11px 14px;
  font-size: .875rem;
  font-family: dinpro;
}