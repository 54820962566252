.modal {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: rgba(0,0,0,.8);
	z-index: 2;
	.modalContentWrap {
		animation-name: popin;
		animation-duration: 0.2s;
		display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
		.modalContent {
			background: #262829;
			color: #fff;
			width: 90%;
			.modalHeader {
				border-bottom: 1px solid #3c3d3e;
				display: flex;
				padding: 0;
				h3 {
					flex-grow: 1;
					padding: 10px;
					margin: 0;
				}
				button {
					color: #888181;
					height: 42px;
					margin: 0;
					font-size: 1rem;
					width: 40px;
					border: none;
					background: none;
				}
			}
			.modalBody {
				padding: 10px;
			}
		}
	}
}

@keyframes popin {
  from {transform: scale(0.3);}
  to {transform: scale(1);}
}

