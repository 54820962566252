.formDropDown {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	width: 100%;

	label {
		font-weight: 400;
		font-size: 0.875rem;
		color: #dab867;
		margin-bottom: 0;
		display: block;
		max-width: 100%;
	}
	select {
		border: 0;
		border-bottom: 1px solid #476370;
		border-radius: 0;
		-moz-appearance: none;
		appearance: none;
		-webkit-appearance: none;
		color: #d3dee3;
		outline: 0;
		padding: 10px 15px 10px 0;
		font-size: 14px;
		box-shadow: none;
		display: block;
		width: 100%;
	}
	option {
		background-color: #1f1f1f;
		color: #d3dee3;
		text-shadow: none;
	}
	.errorMessage {
		color: red;
		font-size: 10px;
	}
}

html[lang='en'],
html[lang='in'],
html[lang='au'],
html[lang='id'] {
	.formDropDown {
		font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif' !important;
		label {
			font-family: 'DIN Pro', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif' !important;
		}
	}
}

html[lang='kh'] {
	.formDropDown {
		font-family: 'Kantumruy', 'Open Sans', 'Khmer OS System', 'sans-serif' !important;
		label {
			font-family: 'Noto Sans Khmer', 'DIN Pro, Kantumruy', 'Khmer OS System',
				'sans-serif' !important;
		}
	}
}

html[lang='kr'] {
	.formDropDown {
		font-family: 'Gothic A1', 'Nanum Gothic', 'Malgun Gothic', 'sans-serif' !important;
		label {
			font-family: 'Noto Sans KR', 'Gothic A1', 'Nanum Gothic', 'Malgun Gothic',
				'sans-serif' !important;
		}
	}
}

html[lang='th'] {
	.formDropDown {
		font-family: 'Krub', 'CS PraKas', 'Helvetica', 'Arial', 'sans-serif' !important;
		label {
			font-family: 'Sarabun', 'Krub', 'CS PraKas', 'Helvetica', 'Arial',
				'sans-serif' !important;
		}
	}
}

html[lang='vn'] {
	.formDropDown {
		font-family: 'Open Sans', 'Source Sans Pro', 'Noto Sans', 'Arial',
			'sans-serif' !important;
		label {
			font-family: 'Roboto', 'Open Sans', 'Source Sans Pro', 'Noto Sans',
				'Arial', 'sans-serif' !important;
		}
	}
}

html[lang='cn'] {
	.formDropDown {
	  font-family: 'Noto Sans SC', 'SimHei', 'sans-serif' !important;
		label {
		  font-family: 'Hiragino Sans GB', 'Microsoft YaHei', 'Noto Sans SC, SimHei', 'sans-serif' !important;
		}
	}
}
