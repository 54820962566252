.radio {
	label {
		font-weight: 400;
		font-size: 0.875rem;
		color: #dab867;
		margin-bottom: 0;
	}
	span {
		margin-right: 20%;
	}

	span > label {
		color: white;
		font-weight: bold;
		margin-left: 5px;
	}

	.usdtLabel {
		color: rgb(45, 197, 137);
	}

	input {
		display: inline-block;
		margin-left: 0px;
		margin-top: 20px;
	}
}
