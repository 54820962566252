.gameList {
	position: relative;
	border-bottom: 4px solid #022635;
	padding: 4px 0;
	color: #fff;
	width: 100%;
	&.vertically {
		padding: 0;
		.items {
			white-space: normal;
			display: flex;
			flex-wrap: wrap;
			padding: 0;
			a {
				margin: 0 2.5% 5%;
				width: 28.3%;
				.img {
					width: 100%;
					padding-bottom: 100%;
					margin: 0;
				}
			}
		}
	}
	.header {
		margin-bottom: 5px;
		padding: 0 15px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		font-family: 'Arial';
		.moreLink {
			font-size: 0.775rem;
			text-transform: uppercase;
			color: #2a8fbd;
			text-decoration: none;
			font-weight: 700;
			display: block;
			&:hover {
				color: #fff;
			}
		}
		.title {
			color: #dab867;
			font-size: 1.125rem;
		}
	}
	.items {
		padding: 15px;
		padding-top: 0;
		overflow-y: hidden;
		overflow-x: auto;
		white-space: nowrap;
		.a {
			margin-right: 10px;
			font-size: 0.75rem;
			text-align: left;
			color: #fff;
			text-decoration: none;
			display: inline-block;
			width: 88px;
			vertical-align: top;
			white-space: normal;
			&:last-child {
				margin-right: 0;
			}
			span {
				display: block;
				word-wrap: break-word;
			}
			.label {
				width: 88px;
				text-align: center;
			}
			.imgWrap {
				width: 88px;
				padding-bottom: 88px;
				display: block;
				margin-bottom: 8px;
				position: relative;
				height: 0;
				border-radius: 4px;
				overflow: hidden;
				z-index: 1;
				.img {
					height: auto !important;
					border-radius: 4px;
					width: 100%;
					height: auto;
					min-height: 1px;
					top: 0;
					left: 0;
					display: block;
					position: absolute;
				}
			}
		}
	}
}
