.ringloader {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 8px;
    border: 5px solid #fff;
    border-radius: 50%;
    animation: ringload 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

.fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index:99;
    background: rgba(0,0,0,0.4);
    .ringloader { 
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

@keyframes ringload {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
